<template>
  <div>
    <NotFound404Error />
  </div>
</template>

<script setup>
import { useMainStore } from "@/store/index";

const props = defineProps({
  error: Object,
});
const nuxtApp = useNuxtApp();
const store = useMainStore();
const route = useRoute();

const defaultRoute = ref(null);
// onServerPrefetch(async () => {
try {
  if (Object.keys(route.params).length) {
    const [lastSelectedCountry, lastSelectedLocale] =
      route.params.country.split("-");

    if (!lastSelectedCountry || lastSelectedCountry == undefined) {
      store.loadTranslationJsonData("int-en", "/int-en");
      defaultRoute.value = "/int-en";
    } else {
      updateCall(lastSelectedCountry, lastSelectedLocale);
    }
  } else if (route.fullPath && route.fullPath.split("/").length > 1) {
    const routeCountrySlug = route.fullPath.split("/")[1];

    if (routeCountrySlug.includes("-")) {
      const [lastCountry, lastLocale] = routeCountrySlug.split("-");

      if (!lastCountry || lastLocale == undefined) {
        store.loadTranslationJsonData("int-en", "/int-en");
        defaultRoute.value = "/int-en";
      } else {
        updateCall(lastCountry, lastLocale);
      }
    } else {
      defaultRoute.value = "/int-en";
      store.loadTranslationJsonData("int-en", "/int-en");
    }
  } else {
    defaultRoute.value = "/int-en";
    store.loadTranslationJsonData("int-en", "/int-en");
  }
} catch (e) {
  console.log(e);
}
// });
async function updateCall(lsc, lsl) {
  const { data } = await useAPI("api/sites");

  if (data.value) {
    // Check if the stored country in cookie exists as an oficial site
    const previousSite = data.value.find((country) => country.slug === lsc);

    if (previousSite) {
      const redirectUrlLocale = previousSite.urls.find(
        (locale) => locale.locale_code === lsl
      );

      if (
        !redirectUrlLocale &&
        previousSite &&
        previousSite.urls.length &&
        previousSite.urls[0]
      ) {
        await store.loadTranslationJsonData(
          lsl + "-" + previousSite.urls[0].locale_code,
          previousSite.urls[0].url
        );
        defaultRoute.value = previousSite.urls[0].url;
      } else {
        await store.loadTranslationJsonData(
          lsc + "-" + lsl,
          previousSite.urls[0].url
        );
        defaultRoute.value = redirectUrlLocale.url;
      }
    } else {
      defaultRoute.value = "/int-en";
      await store.loadTranslationJsonData("int-en", "/int-en");
    }
  } else {
    defaultRoute.value = "/int-en";
    await store.loadTranslationJsonData("int-en", "/int-en");
  }
}
definePageMeta({
  layout: "blank",
});
</script>
