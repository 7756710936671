import { useMainStore } from "../store/index";
import Intercom from '~/utils/intercom';

const intercomInstalled = false
let $intercom = null

function createIntercomPlaceholder() {
    const placeholder = (...args) => placeholder.c(args)
    placeholder.queue = []
    placeholder.c = args => placeholder.queue.push(args)
    return placeholder
}

function includeIntercomScript(appId, callback) {
    const intercomScript = document.createElement('script')
    intercomScript.async = true
    intercomScript.src = `https://widget.intercom.io/widget/${appId}`
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(intercomScript, firstScript)

    intercomScript.addEventListener('load', callback)
}

export default defineNuxtPlugin((nuxtApp) => {
    const store = useMainStore();

    if (!import.meta.browser) {
        return
    }

    if (!store.country) {
        return
    }

    if (!store.currentSite || !store.currentSite.settings.intercom) {
        return
    }

    if (!store.currentSite.settings.intercom.integrate) {
        return
    }

    if (intercomInstalled) {
        return
    }

    const APP_ID =
        store.currentSite && store.currentSite.settings && store.currentSite.settings.intercom.app_id !== null
            ? store.currentSite.settings.intercom.app_id
            : `${nuxtApp.$config.INTERCOM_APP_ID}`

    $intercom = new Intercom(APP_ID, { debug: false })

    nuxtApp.provide('intercom', $intercom)

    if (typeof window.Intercom === 'function') {
        $intercom.init()
        $intercom.call('reattach_activator')
        $intercom.update()
        nuxtApp.provide('loadIntercom', null)
    } else {
        // eslint-disable-next-line no-var
        window.Intercom = createIntercomPlaceholder()

        // const callWhenIntercomScriptLoaded = initialiseIntercom(ctx, APP_ID)
        const callWhenIntercomScriptLoaded = () => {
            $intercom.init()
            $intercom.boot({ app_id: APP_ID })
        }

        const callWhenPageLoaded = () =>
            includeIntercomScript(APP_ID, callWhenIntercomScriptLoaded)

        nuxtApp.provide('loadIntercom', callWhenPageLoaded)
    }
})

// function callWhenPageLoaded (callback) {
//   console.log(callback)
//   if (window.attachEvent) {
//     window.attachEvent('onload', callback)
//   } else {
//     window.addEventListener('load', callback, false)
//   }
// }
