import { useMainStore } from "~/store";

export default defineNuxtRouteMiddleware((to, from) => {
  const store = useMainStore();
  const currentSite = store.currentSite;

  if (
    currentSite &&
    currentSite.settings &&
    !currentSite.settings.test_ride_inquiry &&
    to.path.includes(store.pageLinks["test-drive"])
  ) {
    throw createError({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    currentSite &&
    currentSite.settings &&
    !currentSite.settings.become_a_partner &&
    to.path.includes(store.pageLinks["become-a-dealer"])
  ) {
    throw createError({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    currentSite &&
    currentSite.settings &&
    !currentSite.settings.become_a_partner &&
    to.path.includes(store.pageLinks['become-a-distributor'])
  ) {
    throw createError({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    currentSite &&
    currentSite.settings &&
    !currentSite.settings.work_with_us &&
    to.path.includes(store.pageLinks["work-with-us"])
  ) {
    throw createError({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
});