import { useAuthStore } from "../store/auth";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const { $cookies } = nuxtApp;
    const route = useRoute();

    let headers = {};

    if (route.params.country) {
        const [country, locale] = route.params.country.split("-");
        if (country) {
            headers = {
                "X-Tenant": country,
                "X-Locale": locale,
            };
        }
    }

    const token = $cookies.get("AuthTkn");

    if (token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${token}`,
        };
    }

    const api = $fetch.create({
        baseURL: config.public.baseURL,
        onRequest({ request, options }) {
            // You can add global headers here
            options.headers = {
                ...options.headers,
                ...headers,
            };
        },
        onResponse({ request, response, options }) {
            // Handle the response here
        },
        onResponseError({ request, response, options }) {
            // Handle the error here
            console.error("Fetch error: ", response.status, response.statusText);
            if (response && response.status === 401) {
                useAuthStore().logout();
            }

            return Promise.reject(response);
        },
    });

    // nuxtApp.provide("api", fetch);
    return {
        provide: {
            api
        }
    }
});