<template>
  <div class="flex flex-col justify-between h-screen">
    <div
      class="flex error-container items-end sm:items-center content-center h-screen text-white bg-[#0f0f0f] bg-no-repeat bg-cover bg-center"
    >
      <div
        class="absolute inset-0 bg-[#0f0f0f] bg-opacity-50 mix-blend-multiply z-10"
      />
      <div
        class="relative z-20 mx-auto space-y-8 text-center sm:pt-20 max-w-7xl"
      >
        <div>
          <div class="flex items-center justify-center">
            <Svg404 class="w-[144px] h-auto md:w-auto text-primary" />
          </div>

          <div class="min-h-[90px]" v-if="store">
            <client-only>
              <h1
                v-if="!store.isLoading"
                class="w-full max-w-xl mx-auto my-3 text-[22px] italic font-medium text-center md:text-4xl font-ff-good-pro-cond"
              >
                {{ $t("common.page_not_found_homepage") }}
              </h1>
              <a
                v-if="!store.isLoading"
                :href="store.defaultRoute"
                class="inline-block mt-0 text-base italic font-bold text-white transition-all duration-200 md:text-[22px] hover:text-primary hover:no-underline font-ff-good-pro-cond"
              >
                {{ $t("common.return_to_home") }}
              </a>
            </client-only>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Svg404 from "~/components/404NotFoundIcon.vue";
import { useMainStore } from "@/store/index";

const store = useMainStore();
</script>
<style >
.error-container {
  background-image: url("/error-bg.png");
}
@media (max-width: 640px) {
  .error-container {
    background-image: url("/error-mobile-bg.png");
    @apply bg-top;
  }
}
</style>