import { useMainStore } from "~/store";

export default defineNuxtPlugin((nuxtApp) => {
  if (!import.meta.browser) return false;
  const staticArr = ["about-us", "news", "calculate-bike-size"];
  const arr = ["country-products-slug"];
  const store = useMainStore();
  const router = useRouter();

  if (store && store.pageLinks) {
    const pageName = nuxtApp.$getKeyByValue(store.pageLinks, router.currentRoute.value.params.page);

    let enabledXyz = false;

    if ((staticArr.includes(pageName) || arr.includes(router.currentRoute.value.name)) && !enabledXyz) {
      import("@animxyz/vue3").then((VueAnimXYZ) => {
        import("@animxyz/core").then((c) => {
          enabledXyz = true;
          nuxtApp.vueApp.use(VueAnimXYZ.default);
        });
      });
    }
    nuxtApp.hook("page:start", () => {
      const dynamicName = nuxtApp.$getKeyByValue(store.pageLinks, router.currentRoute.value.params.page);
      if ((staticArr.includes(dynamicName) || arr.includes(router.currentRoute.value.name)) && !enabledXyz) {
        import("@animxyz/vue3").then((VueAnimXYZ) => {
          import("@animxyz/core").then((c) => {
            enabledXyz = true;
            nuxtApp.vueApp.use(VueAnimXYZ.default);
          });
        });
      }
    });
  }
});
