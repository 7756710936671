import { useMainStore } from "../store/index";

const iubSettings = {
    en: {
        locale: "en",
        cookiePolicyId: 43668057,
    },
    es: {
        locale: "es",
        cookiePolicyId: 54099902,
    },
    it: {
        locale: "it",
        cookiePolicyId: 15876090,
    },
    pt: {
        locale: "pt",
        cookiePolicyId: 21464713,
    },
    el: {
        locale: "el",
        cookiePolicyId: 80764886,
    },
};

export default defineNuxtPlugin((nuxtApp) => {
    const store = useMainStore();

    const currentIubSettings = iubSettings[store.locale] || iubSettings.en;

    if (!store.country) {
        return;
    }

    const getIubendaEmbedUrl = (type) => {
        const generateIubendaUrl = (type, iubLangSettings) => {
            const typesUrlFormat = {
                "privacy-policy": "https://www.iubenda.com/privacy-policy/$POLICY_ID",
                "cookie-policy":
                    "https://www.iubenda.com/privacy-policy/$POLICY_ID/cookie-policy",
                terms: {
                    en: "https://www.iubenda.com/terms-and-conditions/$POLICY_ID",
                    es: "https://www.iubenda.com/condiciones-de-uso/$POLICY_ID",
                    it: "https://www.iubenda.com/termini-e-condizioni/$POLICY_ID",
                    el: "https://www.iubenda.com/roi-kai-propothsei/$POLICY_ID",
                },
            };

            const currentType = typesUrlFormat[type] || null;
            const replacePolicyId = (string) =>
                string.replace("$POLICY_ID", iubLangSettings.cookiePolicyId);

            if (!currentType) {
                return;
            }

            // Sometimes the page url contains multiple languages and formats
            if (typeof currentType === "object") {
                return replacePolicyId(currentType[iubLangSettings.locale]);
            }

            return replacePolicyId(currentType);
        };

        return generateIubendaUrl(type, currentIubSettings);
    };

    nuxtApp.provide("loadIubendaCookieConsent", () => {
        if (!import.meta.browser) {
            return;
        }

        window._iub = window._iub || [];
        window._iub.cons_instructions = window._iub.cons_instructions || [];
        window._iub.cons_instructions.push([
            "init",
            { api_key: "hrKwkLf1OdQuttYdbpESBFL5h5F6lr5R" },
        ]);

        window._iub.csConfiguration = {
            localConsentPath: `/${store.country}-${store.locale}`,
            lang: currentIubSettings.locale,
            siteId: 2922858,
            cookiePolicyId: currentIubSettings.cookiePolicyId,
            enableCcpa: true,
            countryDetection: true,
            reloadOnConsent: false,
            askConsentAtCookiePolicyUpdate: true,
            consentOnContinuedBrowsing: false,
            perPurposeConsent: true,
            ccpaAcknowledgeOnDisplay: true,
            whitelabel: false,
            enableCMP: false,
            banner: {
                acceptButtonDisplay: true,
                customizeButtonDisplay: true,
                acceptButtonColor: "black",
                rejectButtonDisplay: true,
                rejectButtonColor: "white",
                rejectButtonCaptionColor: "#6d6d6d",
                position: "top",
                backgroundOverlay: true,
                textColor: "black",
                backgroundColor: "white",
            },
            callback: {
                onPreferenceExpressedOrNotNeeded(preference) {
                    /* eslint-disable */
                    dataLayer.push({
                        iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut(),
                    });

                    if (!preference) {
                        dataLayer.push({
                            event: "iubenda_preference_not_needed",
                        });
                    } else {
                        if (preference.consent === true) {
                            dataLayer.push({
                                event: "iubenda_consent_given",
                            });
                        } else if (preference.consent === false) {
                            dataLayer.push({
                                event: "iubenda_consent_rejected",
                            });
                        } else if (preference.purposes) {
                            for (var purposeId in preference.purposes) {
                                if (preference.purposes[purposeId]) {
                                    dataLayer.push({
                                        event: "iubenda_consent_given_purpose_" + purposeId,
                                    });
                                }
                            }
                        }
                    }
                    /* eslint-enable */
                },
            },
        };

        const scripts = [
            {
                src: "https://cdn.iubenda.com/cs/ccpa/stub.js",
            },
            {
                src: "https://cdn.iubenda.com/cs/iubenda_cs.js",
                async: true,
            },
            {
                src: "https://cdn.iubenda.com/cons/iubenda_cons.js",
                async: true,
            },
        ];

        scripts.forEach((scriptObject) => {
            const element = document.createElement("script");
            // eslint-disable-next-line no-return-assign
            Object.keys(scriptObject).forEach(
                (key) => (element[key] = scriptObject[key])
            );

            document.head.appendChild(element);
        });
    });

    nuxtApp.provide("loadIubendaEmbedScript", (type) => {
        if (process.browser) {
            const script = document.createElement("script");
            script.src = "https://cdn.iubenda.com/iubenda.js";
            script.defer = true;

            document.head.appendChild(script);

            return getIubendaEmbedUrl(type);
        }
    });

    nuxtApp.provide("getIubendaEmbedUrl", getIubendaEmbedUrl);
});
