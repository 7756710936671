import { sortBy, groupBy } from "lodash-es";
import { useMainStore } from "./index";

export const useLocaleStore = defineStore("locale", {
  state: () => ({
    locales: [],
    defaultLocales: [],
    isLoading: true,
    hasClickoutSide: false,
    defaultContry: null,
    defaultContryName: "",
    countries: "",
  }),

  actions: {
    handleClickOutside(result) {
      this.hasClickoutSide = result;
    },
    handleLocaleUpdating() {
      const locales = this.defaultLocales;

      this.locales = locales;
      this.defaultLocales = locales;
      // this.europeCountries.forEach(element => this.locales.push(element))
      this.locales = sortBy(this.locales, "id");
      this.locales = groupBy(
        this.locales.filter((region) => {
          return region.settings.under_maintenance === false;
        }),
        "country.region"
      );
    },
    async fetchLCountries() {
      try {
        const nuxtApp = useNuxtApp();
        this.isLoading = true;
        const { data } = await nuxtApp.$api("/api/front/countries");

        this.countries = data ? data : [];
      } catch (e) {

      }
    },
    async fetchLocales() {
      try {
        // const nuxtApp = useNuxtApp();
        this.isLoading = true;
        const { data } = await useAPI("/api/sites");

        const locales = data._rawValue;

        const country = locales.find((x) => x.slug === useMainStore().country);

        this.defaultContry = country;
        this.defaultContryName = country ? country.country.name : "";

        this.locales = locales;
        this.defaultLocales = locales;
        // this.europeCountries.forEach(element => this.locales.push(element))
        this.locales = sortBy(this.locales, "id");
        this.locales = groupBy(
          this.locales.filter((region) => {
            return region.settings.under_maintenance === false;
          }),
          "country.region"
        );
        // this.locales = groupBy(this.locales.filter((region) => {
        //   return region.country.region !== 'Europe'
        // }), 'country.region')
        this.isLoading = false;
      } catch (e) {
        console.log("Error: ", e);
        console.error("Error: ", e);
        this.isLoading = false;
      }
    },
  },

  getters: {
    getLocales(state) {
      return state.locales;
    },
    getHasClickoutSide(state) {
      return state.hasClickoutSide;
    },
    getDefaultLocales(state) {
      return state.defaultLocales;
    },
    getDefaultContry(state) {
      return state.defaultContry;
    },
    getDefaultContryName(state) {
      return state.defaultContryName;
    },
  },
});
