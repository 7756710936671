import { useMainStore } from "../store/index";

export default defineNuxtPlugin((nuxtApp) => {
    const store = useMainStore();

    const isHMR = import.meta.hot ? true : false;
    const $config = nuxtApp.$config.public;
    const isBrowser = import.meta.browser ? true : false;

    // if (!isHMR) {
    //     return
    // }
    if (!isBrowser) {
        return
    }
    const loadGoogleMaps = () => new Promise((resolve, reject) => {
        window.initMap = resolve
        const mapId = 'bb21f8f7be75a8ab'
        const script = document.createElement('script')

        script.src = `https://maps.googleapis.com/maps/api/js?key=${$config.GOOGLE_API}&map_ids=${mapId}&libraries=geometry,places,marker&language=${store.locale}&region=${store.country}&callback=initMap`
        script.defer = true

        document.head.appendChild(script)
    })


    nuxtApp.provide('loadGoogleMaps', loadGoogleMaps);

    // return {
    //     provide: { loadGoogleMaps },
    // };
})
